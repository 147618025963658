/** @format 
 * 
 *      "name": "test shop",
      "address": null,
      "phone": null,
      "whatsapp": null,
      "passport": null,
      "patent": null,
      "images": null,
      "rating": null,
      "id": 1,
      "createdon": "2024-03-27T07:52:06.000Z",
      "createdby_id": 1,
      "updatedon": "2024-03-27T07:58:37.000Z",
      "updatedby_id": 1,
      "user_id": null,
      "organization_id": 2,
      "status": 1
*/

const supply = {
  title: 'Поставки',
  routeName1: 'supply',
  accessName: 'supply',
  api: '/mko/supply',
  statuses: [
    { value: 1, text: 'Не подписан' },
    { value: 2, text: 'Подписан' },
  ],
  list: [
    { name: 'id', title: '#', type: 'id', sortable: true, width: 50 },
    { name: 'organization_id', title: 'Организация', type: 'selectapi', api: 'users/organization/select', sortable: true, width: 120, access: { role: [1000] } },
    { name: 'shop_name', title: 'Магазин', type: 'string', sortable: true, width: 200 },
    { name: 'code', title: 'Номер накладной', type: 'string', sortable: true, width: 150 },
    { name: 'createdon', title: 'Дата', type: 'datetime', sortable: true, width: 120 },
    { name: 'amount', title: 'Сумма', type: 'number', sortable: true, width: 120 },
    { name: 'amount_paid', title: 'Наличка', type: 'number', sortable: true, width: 120 },
    { name: 'amount_loaned', title: 'Кредит', type: 'number', sortable: true, width: 120 },
    { name: 'amount_due', title: 'Долг', type: 'number', sortable: true, width: 120, hidden: true },
    { name: 'user_name', title: 'Экспедитор', type: 'string', sortable: true, width: 200 },
    { name: 'status', title: 'Статус', type: 'select', sourceList: 'statuses', sortable: true, width: 120 },
  ],
  form: [
    { name: 'code', title: 'Номер накладной', type: 'string', validator: ['req'], autofocus: true },
    { name: 'amount', title: 'Сумма по накладной', type: 'number', validator: ['req'] },
    { name: 'amount_paid', title: 'Сумма оплаты сейчас', type: 'number', validator: ['req'] },
    {
      name: 'amount_loaned',
      title: 'Сумма кредитования',
      type: 'number',
      formula: '(d)=>{return (d.amount || 0) - (d.amount_paid || 0)}',
      validator: ['reqF'],
      validate: { func: '(d)=>{let a=d.amount_due || 0; return !(a>0 && a<5000)}', error: 'значение должно быть больше 5000' },
    },
    {
      name: 'amount_due',
      title: 'Долг',
      type: 'number',
      formula: '(d)=>{return (d.amount || 0) - (d.amount_paid || 0)}',
    },
    { name: 'images1', title: 'Фото-документы', type: 'images', video: false, validator: [] },
    { name: 'document', title: 'Фото-документы', type: 'imagesdata', filesData: true, category: 1, video: false, validator: [] },
    { name: 'report', title: 'Фото-отчет', type: 'imagesdata', filesData: true, category: 2, video: false, validator: [] },
    {
      name: 'shop_id',
      title: 'Магазин',
      type: 'selectapi',
      api: '/mko/shops',
      itemField: { text: 'shop_name', value: 'shop_id' },
      validator: ['req'],
    },
    { name: 'organization_id', title: 'Организация', type: 'selectapi', api: '/users/organization/select', validator: [] },
    { name: 'user_id', title: 'Экспедитор', type: 'selectapi', api: '/users/deliveryman/select', validator: [] },
    { name: 'status', title: 'Статус', type: 'select', sourceList: 'statuses', default: 1, validator: ['req'] },
    { name: 'data' },
    { name: 'filesRemove', default: [], hidden: true },
    { name: 'filesAdd', default: [], hidden: true },
  ],

  config: {
    default: {
      title: 'Поставка',
      fields: 'code,amount,amount_paid,amount_due,amount_loaned,document,shop_id,organization_id,status,user_id,report,filesAdd,filesRemove',
      fieldsRO: 'amount_paid',
      fieldsForm: 'code,amount#4,amount_paid#4,amount_loaned#4,shop_id#6,document,report',
    },
    role_100: {
      title: 'Поставка',
      fields: 'code,amount,amount_paid,amount_due,amount_loaned,document,shop_id,organization_id,status,user_id,report,filesAdd,filesRemove',
      fieldsRO: 'amount_paid',
      fieldsForm: 'code,amount#4,amount_paid#4,amount_loaned#4,shop_id,document,report',
      fieldsReplace: {
        shop_id: {
          name: 'shop_id',
          title: 'Магазин',
          type: 'selectapi',
          api: '/mko/vendor/shops',
          validator: ['req'],
          itemField: {
            text: 'shop_name',
            value: 'shop_id',
            text_func:
              '(d)=>{let res = d.shop_name; try {let addr=JSON.parse(d.shop_address_fact); res = `#${d.shop_id}  ${d.shop_name} (${addr.city}, ${addr.address})`;} catch (error) {}; return res;}',
          },
        },
      },
    },
    role_50: {
      title: 'Поставка',
      fields: 'code,amount,amount_paid,amount_due,amount_loaned,document,shop_id,organization_id,status,user_id,report,filesAdd,filesRemove',
      fieldsRO: 'amount_due,user_id',
      fieldsForm: 'shop_id,code,amount#4,amount_paid#4,amount_loaned#4,document,report',
      fieldsReplace: {
        shop_id: {
          name: 'shop_id',
          title: 'Магазин',
          type: 'selectapi',
          api: '/mko/deliveryman/vendor/shops',
          itemField: {
            text: 'shop_name',
            value: 'shop_id',
            text_func:
              '(d)=>{let res = d.shop_name; try {let addr=JSON.parse(d.shop_address_fact); res = `#${d.shop_id}  ${d.shop_name} (${addr.city}, ${addr.address})`;} catch (error) {}; return res;}',
          },
          validator: ['req'],
        },
      },
    },
    role_1000: {
      title: 'Поставка',
      fields: 'organization_id,user_id,code,amount,amount_paid,amount_due,amount_loaned,document,shop_id,organization_id,status,user_id,report,filesAdd,filesRemove',
      fieldsRO: 'amount_due',
      fieldsForm: 'organization_id#6,user_id#6,shop_id,code,amount#4,amount_paid#4,amount_due#4,document,report',
      fieldsReplace: {
        shop_id: {
          name: 'shop_id',
          title: 'Магазин',
          type: 'selectapi',
          api: '/mko/admin/vendor/shops',
          itemField: {
            text: 'shop_name',
            value: 'shop_id',
            text_func:
              '(d)=>{let res = d.shop_name; try {let addr=JSON.parse(d.shop_address_fact); res = `#${d.shop_id}  ${d.shop_name} (${addr.city}, ${addr.address})`;} catch (error) {}; return res;}',
          },
          validator: ['req'],
        },
      },
    },
  },
};
export default supply;
