/** @format */

import validate from './validate';

export default {
  mixins: [validate],
  data() {
    return {
      saving: false,
    };
  },
  created() {
    window.addEventListener('keydown', this.pressButtonEvent);
  },
  destroyed() {
    window.removeEventListener('keydown', this.pressButtonEvent);
  },
  methods: {
    pressButtonEvent(e) {
      if (this.submitKey)
        if (e.keyCode == 83 && e.ctrlKey) {
          e.preventDefault();
          this.submit();
          return false;
        }
    },
    async submit(silent = false) {
      await this.beforeSave();
      if (this.saving) return;
      this.saving = true;
      if (await this.validateAll(this.data, silent)) {
        await this.post(this.data, this.api);
        this.saving = false;
        return true;
      }

      this.saving = false;
      return false;
    },
    async post(data, api, silent = false) {
      this.loading = true;
      try {
        let response = await this.$axios.post(api, data);
        this.loading = false;
        let status = response.data.status == 'ok';
        if (status) {
          if (!silent)
            this.$root.$emit('show-info', {
              text: 'Данные записаны',
            });
          if (response.data.id && !this.data.id) {
            Object.assign(this.data, { id: response.data.id });
          }
          if (response.data.data) {
            Object.assign(this.data, response.data.data);
          } else if (response.data.id)
            try {
              let resp = await this.$axios.get(api + `/${response.data.id}`);
              if (resp.data.data) {
                Object.assign(this.data, resp.data.data);
              }
            } catch (error) {
              console.log(error);
              this.$root.$emit('show-info', {
                type: 'error',
                text: 'Ошибка при обновлении данных',
              });
            }
        }

        this.afterSave(data, status);
      } catch (error) {
        this.$root.$emit('show-info', {
          type: 'error',
          text: 'Ошибка при сохранении',
        });
        this.loading = false;
        return;
      }
    },
    afterSave(data, status) {},
    async beforeSave() {},
  },
};
